import React from 'react';
import { Container } from 'react-bootstrap';

function AccelerateBanner() {
  const header = 'Speed up your Startup launch';
  const desc =
    'Accelerate, a Softobotics initiative is designed to help startup founders speed up their product development and sales processes. We offer the right support to streamline your growth journey by providing guidance and mentorship to ensure you launch your product quickly and effectively';
  return (
    <div className='min-vw-100 d-flex align-items-center pt-lg-5 pt-4 pb-0 bg-white'>
      <div className='d-flex flex-column'>
        <Container className=''>
          <p className='color-lightGreen mb-3 text-center fnt-14 fnt-md-16  fw-700'>
            Accelerate By Softobotics
          </p>
          <div className='d-flex justify-content-center align-items-center text-center'>
            <p className='fw-700 fnt-20 fnt-lg-32   mb-3  product-bottom'>{header}</p>
          </div>
        </Container>
        <div className='service-bottom-bg d-flex justify-content-center'>
          <p className='fw-400  fnt-14 fnt-lg-16 pb-lg-5 pb-4 text-center  m-0 ideation-para'>
            {desc}
          </p>
        </div>
      </div>
    </div>
  );
}

export default AccelerateBanner;
